<template>
  <!-- 上传视频前的坐标点选择框 -->
  <a-modal
    v-model:visible="visibleVideo"
    title="飞行路径和时间"
    @ok="handleOk"
    @cancel="handleCancel"
    cancelText="取消"
    okText="上传"
  >
    <div style="margin-bottom: 20px">
      <span style="margin-left: 20px">拍摄时间：</span>
      <a-date-picker
        placeholder="请选择拍摄时间"
        :value="takeTime"
        @change="onChange"
        :locale="zhCN"
        change
      />
    </div>
    <a-form
      ref="formRef"
      :model="dynamicValidateForm"
      v-bind="formItemLayoutWithOutLabel"
      autocomplete="off"
    >
      <a-form-item
        label="起点"
        v-bind="formItemLayout"
        :rules="{
          required: true,
          message: '坐标点不能为空',
          trigger: 'change',
        }"
      >
        <a-input
          v-model:value="startPoint"
          placeholder="请输入经纬度，以英文逗号隔开(117.8,39.2)"
          style="width: 80%"
        />
      </a-form-item>
      <a-form-item
        label="终点"
        v-bind="formItemLayout"
        :rules="{
          required: true,
          message: '坐标点不能为空',
          trigger: 'change',
        }"
      >
        <a-input
          v-model:value="endPoint"
          placeholder="请输入经纬度，以英文逗号隔开(117.8,39.2)"
          style="width: 80%"
        />
      </a-form-item>
      <a-form-item
        v-for="(domain, index) in dynamicValidateForm.domains"
        :key="domain.key"
        v-bind="index === 0 ? formItemLayout : {}"
        :label="index === 0 ? '中间点' : ''"
        :name="['domains', index, 'value']"
        :rules="{
          required: true,
          message: '坐标点不能为空',
          trigger: 'change',
        }"
      >
        <a-input
          v-model:value="domain.value"
          placeholder="请输入经纬度，以英文逗号隔开(117.8,39.2)"
          style="width: 80%; margin-right: 8px"
        />
        <MinusCircleOutlined
          class="dynamic-delete-button"
          :disabled="dynamicValidateForm.domains.length === 1"
          @click="removeDomain(domain)"
        />
      </a-form-item>
      <a-form-item v-bind="formItemLayoutWithOutLabel">
        <a-button
          type="dashed"
          style="width: 60%"
          @click="addDomain"
          :disabled="maxTen()"
        >
          <PlusOutlined />
          添加中间坐标点
        </a-button>
      </a-form-item>
    </a-form>
  </a-modal>
  <div class="box">
    <div class="upload_video">
      <a-upload-dragger
        name="file"
        v-model:fileList="fileList"
        :multiple="false"
        :customRequest="customRequest"
        :beforeUpload="beforeUploadVideo"
      >
        <video :src="Vurl" style="display: none" id="videoPlayerNew"></video>
        <p class="ant-upload-text">上传视频</p>
        <p class="ant-upload-drag-icon">
          <inbox-outlined></inbox-outlined>
        </p>
        <p class="ant-upload-text">点击或拖动视频文件到这个区域进行上传</p>
        <p class="ant-upload-hint">
          视频上传，上传视频格式为MP4或MOV，只能上传单个文件
        </p>
      </a-upload-dragger>
      <a-button
        type="primary"
        :disabled="fileNum()"
        size="large"
        @click="handlePic"
        style="margin-top: 20px"
      >
        选择飞行路径
      </a-button>

      <a-modal
        v-model:visible="visible"
        title="正在上传文件，请不要关闭界面"
        :footer="null"
        :closable="false"
      >
        <a-progress :percent="progress" status="active" />
      </a-modal>
    </div>
    <a-modal :visible="lodaingVisible" :footer="null" :closable="false">
      <a-spin>
        <a-alert message="正在上传" description="请不要关闭窗口"></a-alert>
      </a-spin>
    </a-modal>
    <div class="main">
      <div class="photo">
        <a-upload-dragger
          name="file"
          :multiple="true"
          :file-list="fileListP"
          :beforeUpload="beforeUpload"
          @change="handleChange"
          :customRequest="customRequest"
          :showUploadList="false"
        >
          <video :src="Vurl" style="display: none" id="videoPlayerNew"></video>
          <p class="ant-upload-text">上传图片</p>
          <p class="ant-upload-drag-icon">
            <inbox-outlined></inbox-outlined>
          </p>
          <p class="ant-upload-text">点击或拖动图片文件到这个区域进行上传</p>
          <p class="ant-upload-hint">图片上传，上传图片格式为PNG或JPG</p>
        </a-upload-dragger>
      </div>
      <div class="file">
        <a-upload-dragger
          directory
          name="file"
          :multiple="true"
          :file-list="fileListP"
          :beforeUpload="beforeUpload"
          @change="handleChange"
          :customRequest="customRequest"
          :showUploadList="false"
        >
          <p class="ant-upload-text">上传图片文件夹</p>
          <p class="ant-upload-drag-icon">
            <inbox-outlined></inbox-outlined>
          </p>
          <p class="ant-upload-text">点击或拖动文件夹到这个区域进行上传</p>
          <p class="ant-upload-hint">
            请把需要上传的图片放入一个文件夹中，上传图片格式为PNG或JPG
          </p>
        </a-upload-dragger>
      </div>
    </div>
    <div class="pic-box" ref="pic_ref" style="display: none">
      <div class="delete-box">
        <a-button
          type="danger"
          @click="deletePic"
          shape="circle"
          class="a-button"
          >×</a-button
        >
      </div>
      <div id="feature-pic"></div>
    </div>
  </div>
</template>
<script>
import {
  InboxOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons-vue";
import { message } from "ant-design-vue";
import { getAnyDate } from "../../tools/datefmt";
import { defineComponent } from "vue";
import OSS from "ali-oss";
import zhCN from "ant-design-vue/es/locale/zh_CN";
import moment from "moment";
import "moment/dist/locale/zh-cn";

moment.locale("zh-cn");

const client = new OSS({
  region: "oss-cn-beijing",
  accessKeyId: "LTAI5tAhC8qbUaiTHPh7dEuA",
  accessKeySecret: "TfALWBoft5i0A2P11Vxd1DGqBqj9bE",
  bucket: "rubbish-demo",
});
export default defineComponent({
  components: {
    InboxOutlined,
    MinusCircleOutlined,
    PlusOutlined,
  },
  data() {
    return {
      zhCN: zhCN,
      // 上传时选择视频的拍摄时间
      takeTime: getAnyDate(0),
      formItemLayout: {
        labelCol: {
          xs: {
            span: 24,
          },
          sm: {
            span: 4,
          },
        },
        wrapperCol: {
          xs: {
            span: 24,
          },
          sm: {
            span: 20,
          },
        },
      },
      formItemLayoutWithOutLabel: {
        wrapperCol: {
          xs: {
            span: 24,
            offset: 0,
          },
          sm: {
            span: 20,
            offset: 4,
          },
        },
      },
      dynamicValidateForm: {
        domains: [],
      },
      fileList: [], //没有用
      fileListP: [],
      url: "",
      Vurl: "",
      pictureurl: "",
      flag: true,
      visible: false,
      progress: 0,
      vtime: "",
      countUp: 0,
      countDone: 0,
      countOss: 0,
      lodaing: false,
      lodaingVisible: false,
      visibleVideo: false,
      points: [],
      startPoint: "",
      endPoint: "",
    };
  },
  methods: {
    onChange(value, dateString) {
      this.takeTime = dateString;
    },
    // 在输入坐标点表单中的一些控制函数
    // 开始弹出路径信息的选择框
    handlePic() {
      this.visibleVideo = true;
    },
    // 控制只能最多输入十个点
    maxTen() {
      return this.dynamicValidateForm.domains.length === 10;
    },
    // 新建一个input输入框
    addDomain() {
      console.log(this.dynamicValidateForm.domains);
      if (this.dynamicValidateForm.domains.length === 10) return;
      this.dynamicValidateForm.domains.push({
        value: "",
        key: Date.now(),
      });
      this.dynamicValidateForm.weidu.push({
        value: "",
        key: Date.now(),
      });
    },
    // 删除一个input输入框
    removeDomain(item) {
      let index = this.dynamicValidateForm.domains.indexOf(item);
      if (index !== -1) {
        this.dynamicValidateForm.domains.splice(index, 1);
        this.dynamicValidateForm.weidu.splice(index, 1);
      }
    },
    // 上传表格的内容
    handleOk() {
      let temp1 = { gps: "" };
      temp1.gps = this.startPoint;
      this.points[0] = temp1;
      for (let i = 0; i < this.dynamicValidateForm.domains.length; i++) {
        let temp2 = { gps: "" };
        temp2.gps = this.dynamicValidateForm.domains[i].value;
        this.points[i + 1] = temp2;
      }
      let temp3 = { gps: "" };
      temp3.gps = this.endPoint;
      this.points[this.dynamicValidateForm.domains.length + 1] = temp3;
      for (let i = 0; i < this.points.length; i++) {
        if (this.points[i].gps === "" || !this.points[i].gps.includes(",")) {
          message.error("坐标点格式错误！");
          return;
        }
        let arr = this.points[i].gps.split(",");
        if (arr[0].length === 0 || arr[0].length === 0) {
          message.error("坐标点数据缺失！");
          return;
        }
        let jingdu = this.points[i].gps.substr(
          0,
          this.points[i].gps.indexOf(",")
        );
        let weidu = this.points[i].gps.substr(
          this.points[i].gps.indexOf(",") + 1
        );
        if (jingdu < -180 || jingdu > 180 || weidu < -90 || weidu > 90) {
          message.error("经纬度超出实际范围");
          return;
        }
      }
      this.visibleVideo = false;
      this.handleOK();
    },
    // 退出后重置表格
    handleCancel() {
      this.visibleVideo = false;
      this.dynamicValidateForm.domains.splice(
        0,
        this.dynamicValidateForm.domains.length
      );
      this.points.splice(0, this.points.length);
      this.startPoint = "";
      this.endPoint = "";
      this.takeTime = getAnyDate(0);
    },

    //上传视频
    // 控制视频上传的个数为1
    fileNum() {
      return !(this.fileList.length === 1);
    },
    // 上传视频前 将视频载入数组，并且中止上传的动作
    beforeUploadVideo(file) {
      let name = file.name;
      let suffix = name.substr(name.indexOf("."));
      if (
        suffix === ".mp4" ||
        suffix === ".MOV" ||
        suffix === ".MP4" ||
        suffix === ".mov"
      ) {
        this.fileList.push(file);
        return false;
      } else return false;
    },
    // 确定路径信息后进行视频的上传
    handleOK() {
      let file = this.fileList[0]; //文件本身，这个info可以获取当前的视频
      let name = file.name; //获取文件名字(这个是带后缀的)
      let suffix = name.substr(name.indexOf(".")); //截取文件的后缀
      let showName = name.substr(0, name.indexOf("."));
      let self = this;
      if (
        suffix === ".mp4" ||
        suffix === ".MOV" ||
        suffix === ".MP4" ||
        suffix === ".mov"
      ) {
        this.visible = true;
        let obj = new Date().getTime(); //按时间命名，文件名字
        let storeAs =
          obj + "" + this.$storage.get("userinfo").data.userId + suffix; //file文件总名字
        client
          .multipartUpload(storeAs, file, {
            progress: async function (p) {
              self.progress = parseInt(p * 100);
            },
          })
          .then((result) => {
            this.progress = 0;
            this.Vurl = this.jiequ(result.res.requestUrls[0]);
            let api = "http://39.100.158.75:8080/photo/insertVideoList";
            this.$axios({
              url: api,
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                token: this.$storage.get("userinfo").data.token,
              },
              data: JSON.stringify({
                operatorId: this.$storage.get("userinfo").data.userId,
                videoUrl: this.Vurl,
                videoTime: getAnyDate(0),
                name: result.name,
                fileName: showName,
                checkPoint: this.points,
                takeTime: this.takeTime,
              }),
            }).then((response) => {
              this.dynamicValidateForm.domains.splice(
                0,
                this.dynamicValidateForm.domains.length
              );
              this.points.splice(0, this.points.length);
              this.startPoint = "";
              this.endPoint = "";
              this.takeTime = getAnyDate(0);
              // 上传完毕后将文件数组置为空
              this.fileList = [];
              if (response.data.success) message.success(name + "上传成功!!!");
              else {
                message.error(name + response.data.message);
              }
            });
            this.visible = false;
            message.success("上传成功");
          });
      } else {
        message.error("视频格式错误，只能上传MP4文件或者MOV文件！！！");
      }
    },
    // 文件名称截取处理
    jiequ(a) {
      let n = a.indexOf("?");
      return a.slice(0, n);
    },

    // 上传图片
    // 屏蔽掉原始的上传接口
    customRequest() {
      //屏蔽掉原始的上传接口，原始上传接口的change在上传时，上传中，上传后都调用，直接屏蔽掉
      console.log("customRequest");
    },
    // 上传图片片之前检验照片格式是否正确
    beforeUpload(file) {
      const jspicture =
        file.type === "image/png" ||
        file.type === "image/jpeg" ||
        file.type === "image/jpg";
      if (!jspicture) {
        message.error(file.name + "上传文件格式错误,自动忽略错误格式图片");
        this.flag = false;
      } else {
        this.flag = true;
        this.countUp++;
        this.fileListP.push(file);
      }
      return jspicture;
    },
    // 上传图片
    async handleChange() {
      this.countOss++;
      let photos = [];
      if (this.countOss === this.countUp) {
        this.lodaing = true;
        this.lodaingVisible = true;
        for (let file of this.fileListP) {
          let name = file.name;
          let obj = new Date().getTime();
          let storeAs = obj + "." + name; //file文件总名字
          await client.put(storeAs, file).then((result) => {
            let temp = {};
            temp.operatorId = this.$storage.get("userinfo").data.userId;
            temp.photoUrl = result.url;
            temp.name = result.name;
            temp.updateTime = new Date();
            temp.type = "1";
            photos.push(temp);
            this.countDone++;
          });
        }
        let api = "http://39.100.158.75:8080/photo/insertPhotoList";
        this.$axios({
          url: api,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            token: this.$storage.get("userinfo").data.token,
          },
          data: {
            list: photos,
          },
        })
          .then((res) => {
            this.fileListP = [];
            if (this.countUp === this.countDone) {
              this.visible = false;
              this.lodaingVisible = false;
            }
            if (res.data.success) {
              message.success(res.data.message);
            } else {
              message.error(res.data.message);
            }
          })
          .catch(() => {
            this.fileListP = [];
            this.visible = false;
            this.lodaingVisible = false;
            message.error("上传错误");
          });
      }
      // console.log(info.file);
      // console.log(info.file.name);
      // let file = info.file; //文件本身，这个info可以获取当前的图片
      // console.log(file);
      // let file2 = file.originFileObj;
      // console.log(file2);
      // let name = file.name; //获取文件名字
      // console.log(name);
      // let showName = name.substr(0,name.indexOf("."));
      // console.log(showName);
      // // let suffix = name.substr(name.indexOf(".")); //截取文件的后缀
      // let obj = new Date().getTime(); //按时间命名，文件名字
      // console.log(obj);
      // let storeAs =
      //   obj + "." + name ; //file文件总名字
      // console.log(storeAs);
      // // let self = this;
      // this.lodaing = true;
      // this.lodaingVisible = true;
      // if (this.flag) {
      //   client
      //     .put(storeAs, file2)
      //     .then((results) => {
      //       this.progress=0;
      //       info.file.status = "done";
      //       console.log(results);
      //       console.log(results.url);
      //       this.pictureurl = results.url;
      //       console.log(this.pictureurl);
      //       let api = "http://39.100.158.75:8080/photo/insertPhotoList";
      //       this.$axios({
      //         url: api,
      //         method: "POST",
      //         headers: {
      //           "Content-Type": "application/json",
      //           token: this.$storage.get("userinfo").data.token,
      //         },
      //         data: [
      //           {
      //             operatorId: this.$storage.get("userinfo").data.userId,
      //             photoUrl: this.pictureurl,
      //             name:results.name,
      //             updateTime: new Date(),
      //             type:"1"
      //           },
      //         ],
      //       })
      //         .then((response) => {
      //           this.dynamicValidateForm.domains.splice(0,this.dynamicValidateForm.domains.length);
      //           this.points.splice(0,this.points.length);
      //           this.startPoint = '';
      //           this.endPoint = '';
      //           this.countDone++;
      //           if(this.countUp === this.countDone) {
      //             this.countUp = 0;
      //             this.countDone = 0;
      //             this.visible = false;
      //             this.lodaingVisible = false;
      //           }
      //           if (response.data.success) {

      //             message.success(name + "上传成功");
      //           }else {
      //             message.error(name + response.data.message);
      //           }

      //         })
      //         .catch((error) => {
      //           console.log(error);
      //         });
      //     })
      //     .catch((err) => {
      //       console.log(err);
      //     });
      // }
    },
  },
});
</script>
<style lang="scss">
/deep/ .ant-modal {
  background-color: rgb(0, 0, 0) !important;
}

.box {
  display: flex;
  height: 80%;
  margin-top: 7%;
  justify-content: space-evenly;
}

.upload_video {
  height: 65%;
  width: 38%;
}

.main {
  width: 38%;
  height: 80%;
}

.photo {
  height: 45%;
}

.file {
  height: 45%;
  margin-top: 10%;
}

// 表单中移除某个坐标点的选择按钮
.dynamic-delete-button {
  cursor: pointer;
  position: relative;
  top: 4px;
  font-size: 24px;
  color: #999;
  transition: all 0.3s;
}

.dynamic-delete-button:hover {
  color: #777;
}

.dynamic-delete-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}
</style>